import { FIELD_TYPES } from "constants/template-field-type";
import { BACKGROUND_COLOR_OPTIONS } from "segments/segments-service";

export default {
  theme: {
    type: FIELD_TYPES.COLOR_PICKER,
    label: "Theme",
    possibleColors: BACKGROUND_COLOR_OPTIONS
  }
};
