const FIELD_TYPES = {
  GALLERY: "gallery",
  CONSTANT: "constant",
  COMBO: "combo",
  BOOLEAN: "boolean",
  SEGMENTS: "segments",
  TEXT: "text",
  RICH_TEXT: "rich_text",
  DROPDOWN: "dropdown",
  NESTED: "nested",
  COLOR_PICKER: "colorPicker",
  VIDEO_PICKER: "videoPicker",
  CLOUDINARY_VIDEO_PICKER: "cloudinaryVideoPicker",
  MULTIPLE_TEXTS: "multipleTexts",
  MULTIPLE_NESTED: "multipleNested",
  DYNAMIC: "dynamic",
  HOMEPAGE_URL: "homepageUrl",
  TAGS: "tags",
  CATEGORIES: "categories",
  CLUSTERS: "clusters",
  SUB_CLUSTERS: "sub_clusters",
  FULLSTORY_RECORDING_FREQUENCY: "fullstory_recording_frequency",
  LOCALES: "locales",
  CONDITIONAL_NESTED: "conditionalNested",
  AUTOCOMPLETE: "autocomplete",
  SOLUTION: "solution",
  CALENDAR: "calendar"
};

module.exports = {
  FIELD_TYPES
};
