import { FIELD_TYPES } from "constants/template-field-type";
import ThemeConfig from "segments/core-configs/theme-config";

export default {
  paddingTop: { type: FIELD_TYPES.TEXT, label: "Padding Top" },
  paddingBottom: { type: FIELD_TYPES.TEXT, label: "Padding Bottom" },
  mobilePaddingTop: { type: FIELD_TYPES.TEXT, label: "Mobile Padding Top" },
  segmentMinHeight: {
    type: FIELD_TYPES.TEXT,
    label: "Segment min height"
  },
  mobilePaddingBottom: { type: FIELD_TYPES.TEXT, label: "Mobile Padding Bottom" },
  hideOnMobile: { type: FIELD_TYPES.BOOLEAN, label: "Hide segment on mobile" },
  topTriangle: {
    type: FIELD_TYPES.NESTED,
    label: "Top triangle",
    fields: {
      isVisible: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Is visible"
      },
      color: {
        type: FIELD_TYPES.COLOR_PICKER,
        label: "Color",
        possibleColors: ThemeConfig.theme.possibleColors
      }
    }
  },
  bottomTriangle: {
    type: FIELD_TYPES.NESTED,
    label: "Bottom triangle",
    fields: {
      isVisible: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Is visible"
      },
      color: {
        type: FIELD_TYPES.COLOR_PICKER,
        label: "Color",
        possibleColors: ThemeConfig.theme.possibleColors
      }
    }
  },
  segmentId: {
    type: FIELD_TYPES.TEXT,
    unAllowedChars: ["#"],
    label: "Segment ID",
    labelDescription:
      "To link to this element insert the unique id (without # symbol). Consider adding 72px top padding (because of the header)"
  }
};
